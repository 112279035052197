<template src="./ProfileInformationCard.html">
</template>

<script>
import { genderTransform } from '@/filters/gender';
import ArrobaIdIcon from '@/components/Icons/ArrobaIdIcon';
import { PROFILE_IMAGEN } from '../../../utils/constants';

export default {
  name: 'profile-information-card',
  filters: { genderTransform },
  components: { ArrobaIdIcon },
  data() {
    return {
      PROFILE_IMAGEN
    };
  },
  props: {
    img: { type: String },
    name: { type: String, default: '' },
    id: { type: String, default: '' },
    email: { type: String, default: '' },
    institution: { type: String, default: '' },
    pais: { type: String, default: '' },
    departamento: { type: String, default: '' },
    ciudad: { type: String, default: '' },
    socialMedia: [Array, Object],
    role: { type: String }
  },
  methods: {
    redirectTo(route) {
      this.$router.push({path: `${route}`})
    }
  },
  computed: {
    imageToRender() {
      return this.img ? this.img : this.PROFILE_IMAGEN;
    }
  }
}
</script>

<style lang="scss" scoped src="./ProfileInformationCard.scss">

</style>